body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/src/media/images/FeatherBackground.png');
  background-repeat: repeat-y;
  background-size: cover;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-blue: rgba(55,126,254,1);
  --background-trans-blue: rgba(55,126,254,.5);
  --background-orange: rgba(242,104,34,1);
  --background-trans-orange: rgba(242,104,34,.5);
  --background_1: #9dd2fa;
  --background_2: #0180ff;
  --background_3: #003ab4;
  --very_light_blue: #9dd2fa;
  --blue: #0181ff;
  --dark_blue: #003ab4;
}